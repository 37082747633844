import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from '@Components/common';
import { Divider } from '@Components/Divider/Divider';
import { SurveySection } from '@Components/Surveys/SurveySection/SurveySection';
import { QuestionWrapper } from '@Components/Surveys/QuestionWrapper/QuestionWrapper';
import { SurveyProgressIndicator } from '@Components/Surveys/SurveyProgressIndicator/SurveyProgressIndicator';
import { SurveyConfiguration } from '@Types';
import { nonEmptySurveyData } from '@Components/Surveys/utils';
import { surveyValueIsEmpty } from '@Components/Surveys/Utils/surveyValueIsEmpty';

import styles from './Survey.module.scss';
import classnames from 'classnames';
import { ConfirmModal } from '@Components/ConfirmModal/ConfirmModal';
import { useState } from 'react';

export interface SurveyProps {
  surveyConfig: SurveyConfiguration;
  isSaving: boolean;
  onSubmit: (data: any, status: boolean) => void;
  preview?: boolean;
}

const nullOnlySurveyData = (data: any) => {
  // Normalise data we send to the server so all empty data is null.
  // This avoids us sending e.g. '' as the value for an integer field.
  return Object.fromEntries(
    Object.entries(data).map((entry) => {
      const [name, value] = entry;
      if (surveyValueIsEmpty(value)) {
        return [name, null];
      } else {
        return [name, value];
      }
    }),
  );
};

export const Survey = (props: SurveyProps) => {
  const surveyConfig = props.surveyConfig;
  const methods = useForm({ defaultValues: surveyConfig.data || undefined });
  const onSubmit = methods.handleSubmit((data) => {
    const processedData = nullOnlySurveyData(data);
    props.onSubmit(processedData, true);
  });
  const saveAsDraft = () => {
    // Don't send fields with empty data to the server.
    const data = nonEmptySurveyData(methods.getValues());
    props.onSubmit(data, false);
  };
  const { t } = useTranslation('surveys');
  const [isSubmitSurveyConfirmModalVisible, setIsSubmitSurveyConfirmModalVisible] = useState(false);

  const sections: JSX.Element[] = [];
  let currentQuestionNumber = 0;
  surveyConfig.sections.forEach((sectionConfig, sectionIndex) => {
    const questions = sectionConfig.questions.map((questionConfig) => {
      questionConfig.disabled = props.preview;

      currentQuestionNumber += 1;
      return (
        <QuestionWrapper
          key={questionConfig.identifier}
          questionNumber={currentQuestionNumber}
          questionConfig={questionConfig}
        />
      );
    });
    sections.push(
      <SurveySection
        key={sectionIndex}
        label={sectionConfig.label}
        heading={sectionConfig.heading}
        sectionNumber={sectionIndex}
        sectionCount={surveyConfig.sections.length}
      >
        {questions}
      </SurveySection>,
    );
    if (questions.length) {
      sections.push(<Divider key={`divider_${sectionIndex}`} />);
    }
  });

  return (
    <FormProvider {...methods}>
      <div>
        {!props.preview && (
          <SurveyProgressIndicator
            surveyConfig={surveyConfig}
            saveAsDraft={saveAsDraft}
            isSaving={props.isSaving}
          />
        )}
        <form onSubmit={onSubmit} autoComplete="off">
          <div className={classnames(styles.contentContainer, props.preview && styles.fullWidth)}>
            {sections}
            {!props.preview && (
              <div className={styles.buttonRow}>
                <Button onClick={saveAsDraft} buttonType="tertiary" disabled={props.isSaving}>
                  {t('save_as_draft')}
                </Button>
                <Button
                  onClick={() => setIsSubmitSurveyConfirmModalVisible(true)}
                  buttonType="primary"
                  disabled={props.isSaving}
                >
                  {t('submit')}
                </Button>
              </div>
            )}
          </div>
        </form>
      </div>
      <ConfirmModal
        isOpen={isSubmitSurveyConfirmModalVisible}
        onClose={() => setIsSubmitSurveyConfirmModalVisible(false)}
        title={t('confirm_modal.title')}
        cancelText={t('confirm_modal.cancel')}
        onCancel={() => setIsSubmitSurveyConfirmModalVisible(false)}
        confirmText={t('confirm_modal.confirm')}
        onConfirm={onSubmit}
      >
        {t('confirm_modal.text')}
      </ConfirmModal>
    </FormProvider>
  );
};
