import { isArray } from 'lodash';

export const surveyValueIsEmpty = (value: any) => {
  let itemsInObjectAreEmpty = false;
  if (typeof value === 'object') {
    // data structure for the type of questions that get in here:
    // {
    //    outputs: [
    //      {number:'1', description: 'thing'},
    //      {number:'2', description: 'thing 2'}
    //    ]
    // }
    // This loops through all arrays stored in the keys of the object and checks the length of their values
    for (const property in value) {
      itemsInObjectAreEmpty = value[property].length === 0;
      if (!itemsInObjectAreEmpty) {
        break;
      }
    }
  }
  return (
    value == null || value === '' || (isArray(value) && value.length === 0) || itemsInObjectAreEmpty
  );
};
